<template>
  <div class="content-box">
    <div class="content-title">
      <header-entry>确认订单信息</header-entry>
    </div>
    <div class="content-between">
      <div class="content-item-text">{{ orderName }}</div>
      <div class="content-item-price">￥{{ orderAmount }}</div>
    </div>
    <div class="content-line"></div>
    <div class="content-title">
      <header-entry>付款信息</header-entry>
    </div>
    <div class="content-between">
      <div class="content-left">
        <el-radio-group v-model="payWay" class="content-radio-group" v-if="payWay == 1 || payWay == 2">
          <el-radio :label="1" border>
            <span class="content-radio">
              <img src="@/assets/images/comm/pay-alipay.png" class="radio-img">
              <span class="radio-txt">支付宝支付</span>
            </span>
          </el-radio>
          <!-- <el-radio :label="2" border>
            <span class="content-radio">
              <img src="@/assets/images/comm/pay-wechat.png" class="radio-img">
              <span class="radio-txt">微信支付</span>
            </span>
          </el-radio> -->
        </el-radio-group>
      </div>
      <div class="content-right">
        <div class="content-item-spje">付款金额</div>
        <div class="content-item-price">￥{{ orderAmount }}</div>
      </div>
    </div>
    <div class="content-line"></div>
    <div class="content-right">
      <el-button :loading="loading" type="primary" class="content-button" @click="onSubmit">提交订单</el-button>
    </div>
  </div>
</template>

<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import Manager from "@/manager";
import Tools from "@/tool"
import Config from "@/api/service";

export default {
  name: "PCModule",
  components: {
    HeaderEntry,
  },
  data() {
    return {
      loading: false,
      isDocin: false,
      courseGuid: null,
      orderName: null,
      orderAmount: 0,
      payWay: 0,
    };
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.courseGuid = this.$route.query.courseGuid;
    },
    //获取是否豆丁账户
    getIsDocin() {
      var model = {
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.proxy.isDocin, model)
        .then((res) => {
          this.isDocin = res.data.Data.IsDocin;
          if (!this.isDocin) {
            this.payWay = 1;
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //查询课程信息
    getProductInfo() {
      var model = {
        CourseGuid: this.courseGuid,
      };
      this.$axios
        .post(Config.course.courseInfo, model)
        .then((res) => {
          this.orderName = res.data.Data.CourseName;
          this.orderAmount = res.data.Data.CourseSellingPrice;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //提交订单
    onSubmit() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
        return;
      }
      if (Tools.isEmpty(this.payWay)) {
        this.$message.warning('请选择付款方式。');
        return;
      }
      this.$confirm('请确认订单信息，付款后不支持退款。', '订单确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        this.loading = true;
        var model = {
          ProxyCode: this.$store.state.proxyCode,
          CourseGuid: this.courseGuid,
          TerminalType: 0,
          OrderPayForm: this.payWay,
          PayFinishUrl: Manager.goPayFinishUrl(this.$store.state.proxyCode),
        };
        this.$axios
          .post(Config.course.submitOrder, model)
          .then((res) => {
            this.$router.push({
              name: "PayBefore",
              params: {
                proxyCode: this.$store.state.proxyCode
              },
              query: {
                payUrl: res.data.Data.DocinPayUrl
              }
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }).catch(() => {

      });
    }
  },
  mounted() {
    this.getRoutParams();
    this.getIsDocin();
    this.getProductInfo();
  }
};
</script>

<style scoped lang="less">
.content-box {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.content-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}

.content-right {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}

.content-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
}

.content-item-text {
  color: #1e1e1e;
  font-size: 14px;
}

.content-item-spje {
  color: gray;
  font-size: 13px;
  margin-right: 10px;
}

.content-item-price {
  color: #2a2d2e;
  font-size: 16px;
}

.content-radio-group {
  display: flex;
  flex-direction: row;
}

.content-radio {
  display: flex;
  justify-content: center;
  align-items: center;

  .radio-img {
    width: 30px;
  }

  .radio-txt {
    margin-left: 10px;
  }
}

.content-button {
  width: 120px;
  margin-top: 20px;
}

.content-line {
  border-top: 1px solid #e6e6e6;
  margin: 10px 0;
}

::v-deep .el-radio.is-bordered {
  padding: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>